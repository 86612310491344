import { ReactElement } from 'react';
import {
	DrivenBox, DrivenButton, DrivenCard, DrivenLink,
	DrivenStack,
	DrivenTypography,
	LocationResult,
} from '@drivenbrands/driven-components';

import slugify from 'slugify';
import { useMediaQuery, useTheme } from '@mui/material';

import { GatsbyLinkBehavior } from '../gatsby-link-behavior';
import { formatPhoneNumber } from '../forms';
import { getStoreStatus } from '../../utils/storeStatus';

type LocationResultUpdated = LocationResult & {
	currentBrand: string;
	historicBrand: string;
	location_content: {
		windshieldRepairMobile: boolean | number | string;
	};
	mobileRepair: boolean | number | string;
	serviceArea: string
};

type LocationCardProps = {
	handleStoreSelection: () => void;
	isFirstCard: boolean;
	isSelected?: boolean;
	locationActions?: ReactElement;
	store: LocationResultUpdated;
};

export const LocationsCard = ({ isFirstCard, locationActions, store }: LocationCardProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const {
		store_city,
		mobileRepair,
		serviceArea,
		store_address,
		store_state,
		store_postcode,
		store_lat,
		store_long,
		geo_address,
		store_phone,
		store_id,
		hours,
		distance
	} = store;

	const { storeHours, isOpen } = getStoreStatus(hours);
	const storeLink = `/our-locations/${slugify(store_city, { lower: true, strict: true })}-${store_id}/`;

	const mobileRepairContent = mobileRepair ? (
			<>
				<DrivenStack direction='row' spacing={1} alignItems='center'>
					<img src='/assets/images/icons/van-shuttle.svg' alt='Van shuttle icon' />
					<DrivenTypography variant='subtitle1' sx={{ color: '#004E82' }}>
						Mobile service only
					</DrivenTypography>
				</DrivenStack>
				{serviceArea && (
					<DrivenBox>
						<DrivenTypography variant='h6' sx={{ fontSize: { xs: 18 } }}>
							Serving the following areas:
						</DrivenTypography>
						<DrivenTypography>
							{serviceArea
								.split('|')
								.map((area: string) => area.trim())
								.filter((area: string) => area.length > 0)
								.join(', ')}
						</DrivenTypography>
					</DrivenBox>
				)}
			</>
		) : (
			<DrivenBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<DrivenStack direction='column'>
					<DrivenTypography variant='body1'>{store_address}</DrivenTypography>
					<DrivenTypography variant='body1'>
						{store_city}, {store_state} {store_postcode}
					</DrivenTypography>
				</DrivenStack>
				<DrivenLink
					href={`https://www.google.com/maps?ll=${store_lat},${store_long}&z=13&t=m&hl=en-US&gl=US&mapclient=embed&daddr=${geo_address}`}
					target='_blank'
					sx={{
						height: '100%',
						display: 'flex',
						gap: 1,
						fontWeight: 600,
					}}
				>
					<img src='/assets/images/icons/turn-right-diamond.svg' alt='Turn right icon' />
					{!isMobile ? 'Directions' : ''}
				</DrivenLink>
			</DrivenBox>
		);

	return (
		<DrivenCard
			sx={{
				px: 2,
				py: 3,
				width: '100%',
				boxShadow: isMobile ? 'none' : 'default',
				borderTop: isMobile && !isFirstCard ? `1px solid ${theme.palette.grey[300]}` : 'none',
			}}
		>
			<DrivenBox
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					gap: 2,
				}}
			>
				<DrivenBox
					sx={{
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						gap: 2,
					}}
				>
					<DrivenBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<DrivenTypography component='h6' variant='subtitle1' sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 1,
						}}>
							<DrivenLink href={storeLink} LinkComponent={GatsbyLinkBehavior} sx={{ all: 'inherit' }} >
								{store_city}
							</DrivenLink>
							{!mobileRepair && (
								<>
									<DrivenTypography component='span' sx={{ color: theme.palette.grey[300] }}>
										|
									</DrivenTypography>
									<DrivenTypography component='span'>
										{parseFloat(distance.toFixed(2))} mi
									</DrivenTypography>
								</>
							)}
						</DrivenTypography>
						<DrivenBox
							sx={{
								p: 0.5,
								backgroundColor: isOpen ? '#FFD000' : 'rgb(255, 240, 173)',
								fontSize: 14,
								lineHeight: '21px',
							}}
						>
							{storeHours}
						</DrivenBox>
					</DrivenBox>
					{mobileRepairContent}
					<DrivenLink
						href={`tel:${formatPhoneNumber(store_phone)}`}
						sx={{
							display: 'flex',
							gap: 1,
							fontWeight: 600,
						}}
					>
						<img src='/assets/images/icons/phone-square.svg' alt='Phone square icon' />
						{formatPhoneNumber(store_phone)}
					</DrivenLink>
					{locationActions}
					{isMobile && (
						<DrivenButton
							color='primary'
							href={storeLink}
							LinkComponent={GatsbyLinkBehavior}
							variant='outlined'
							sx={{
								borderRadius: 30,
								height: 48,
								maxWidth: 358,
							}}
						>
							View Store Details
						</DrivenButton>
					)}
				</DrivenBox>
				{!isMobile && (
					<DrivenLink href={storeLink} LinkComponent={GatsbyLinkBehavior}>
						<img src='/assets/images/icons/chevron-right.svg' alt='Arrow right icon' />
					</DrivenLink>
				)}
			</DrivenBox>
		</DrivenCard>
	);
};
