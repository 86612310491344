import {
	DrivenBox, DrivenLink, DrivenTypography,
} from '@drivenbrands/driven-components';
import { CrossBrandImage } from './cross-brand-image';

const ExclusiveOffers = (props: any) => {
	const { images } = props;

	return (
		<>
			<DrivenTypography variant='h1' component='h1' sx={{
				fontSize: { xs: 30, sm: 40, md: 48 },
				textAlign: 'center',
				textTransform: { xs: 'capitalize' }
			}}>
				Exclusive offers from our family of brands!
			</DrivenTypography>
			<DrivenBox sx={{
				display: 'flex',
				flexDirection: { xs: 'column', sm: 'row' },
				width: { xs: '100%', md: '670px' },
				mx: 'auto',
				mt: 4,
			}}>
				<DrivenLink
					href='https://www.take5.com/locations/'
					target='_blank'
				>
					<CrossBrandImage image={images[0]} />
				</DrivenLink>
				<DrivenLink
					href='https://www.maaco.com/locations/'
					target='_blank'
				>
					<CrossBrandImage image={images[1]} />
				</DrivenLink>
			</DrivenBox>
		</>
	);
}

export default ExclusiveOffers;
