import {
	DrivenBox,
	DrivenButton,
	DrivenContainer,
	DrivenDropdown,
	DrivenTypography,
	IconTypes_FontAwesome,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react';
import MarkdownContent from '../../mdx/markdown-content';
import { autoInsuranceAnswerOptions, damagedAreasAnswerOptions, glassDamageAnswerOptions } from '../utils';
import autoGlassNowLogo from './images/auto-glass-&-windshield-online-quote-logo.svg';
import { GatsbyLinkBehavior } from '../../gatsby-link-behavior';
import { captureCustomDataLayerEvent } from '../../../services';

const inputMaxHeight = '48px';
const inputMaxWidth = '100%';

const textFieldStyles = {
	maxWidth: inputMaxWidth,
	maxHeight: inputMaxHeight,
	overflow: 'hidden',
	'& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
		padding: '12px 14px',
	},
};

const OnlineQuote: React.FC<any> = ({ ...props }) => {
	const { headline, headlineLevel, headlineStyle, body } = props;
	const theme = useTheme();

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.up('md'));

	const [glassDamage, setGlassDamage] = useState('');
	const [damagedAreas, setDamagedAreas] = useState('');
	const [autoInsurance, setAutoInsurance] = useState('not-sure-yet');

	const determineIframe = () => {
		const iframeProps = {
			height: '100%',
			width: '100%',
			allowFullScreen: true
		}
		const gclid = localStorage.getItem('gclid');

		const appendGclid = (url: string) => {
			if (gclid) {
				const urlObj = new URL(url);
				urlObj.searchParams.append('gclid', gclid);
				return urlObj.toString();
			}
			return url;
		};

		if (autoInsurance === 'yes') {
			return <iframe
				src={appendGclid('https://app.omegaedi.com/quoter15/?folder=agn&campaign=Web%20Quote&Tags=103,67&template_id=111&smart=true')}
				{...iframeProps}
				style={{ border: 'none', overflow: 'hidden', minHeight: isMobile ? '1216px' : '1174px' }}
				title='Insurance Iframe'
			/>;
		}
		if (glassDamage === 'no' || damagedAreas === 'no') {
			return <iframe
				src={appendGclid('https://app.omegaedi.com/quoter/?folder=agn&campaign=Web%20Quote&Tags=103&template_id=1&smart=true')}
				{...iframeProps}
				style={{ border: 'none', overflow: 'hidden', minHeight: isMobile? '723px' : '681px' }}
				title='Replacement Iframe'
			/>;
		}
		if (damagedAreas === 'yes') {
			return <iframe
				src={appendGclid('https://app.omegaedi.com/quoter14/?folder=agn&campaign=Web%20Quote&Tags=103&template_id=110&smart=true')}
				{...iframeProps}
				style={{ border: 'none', overflow: 'hidden', minHeight: isMobile? '650px' : '629px' }}
				title='Repair Iframe'
			/>;
		}
		return null;
	};

	useEffect(() => {
		if(glassDamage === 'yes') {
			setDamagedAreas('no')
		} else {
			setDamagedAreas('')
			setAutoInsurance('not-sure-yet')
		}
	}, [glassDamage])

	return (
		<DrivenContainer maxWidth='lg' sx={{ alignSelf: 'center' }}>
			<DrivenBox
				sx={{
					margin: '0 auto',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
				}}>
				<DrivenTypography
					variant={headlineStyle || 'h2'}
					component={headlineLevel || 'h2'}
					align='center'
				>
					{headline || ''}
				</DrivenTypography>
				<DrivenTypography variant='body1' sx={{
					maxWidth: '856px',
				}}>
					<MarkdownContent>
						{body?.childMarkdownRemark.rawMarkdownBody || ''}
					</MarkdownContent>
				</DrivenTypography>
				<DrivenBox sx={{
					mt: 1,
					display: 'flex',
					flexDirection: isMobile ? 'column' : 'row',
					alignItems: 'center',
					gap: 2
				}}>
					<DrivenButton
						color='primary'
						href='#online-estimate-form'
						variant='contained'
						sx={{
							borderRadius: 30,
							height: 48,
							textTransform: 'uppercase',
							width: 'fit-content',
						}}
						onClick={() => {
							captureCustomDataLayerEvent('cta_online_estimate_start');
						}}
					>
						Start online quote
					</DrivenButton>
					<DrivenTypography component='span' sx={{
						fontWeight: 700,
					}}>OR</DrivenTypography>
					{isMobile ? (
						<DrivenButton
							color='primary'
							href='tel:1-888-434-9452'
							variant='contained'
							LinkComponent={GatsbyLinkBehavior}
							icon={IconTypes_FontAwesome.PHONE}
							sx={{
								borderRadius: 30,
								height: 48,
								textTransform: 'uppercase',
								width: 'fit-content',
							}}
							onClick={() => {
								captureCustomDataLayerEvent('cta_online_estimate_call');
							}}
						>
							call for a quote
						</DrivenButton>
					): (
						<DrivenButton
							color='primary'
							href='tel:1-888-434-9452'
							variant='contained'
							LinkComponent={GatsbyLinkBehavior}
							icon={IconTypes_FontAwesome.PHONE}
							sx={{
								borderRadius: 30,
								height: 48,
								textTransform: 'uppercase',
								width: 'fit-content',
							}}
							onClick={() => {
								captureCustomDataLayerEvent('cta_online_estimate_call');
							}}
						>
							1-888-4-FIX-GLASS
						</DrivenButton>
					)}
				</DrivenBox>
			</DrivenBox>
			<DrivenBox
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'flex-start',
					flexDirection: isTablet ? 'row' : 'column',
					gap: isTablet ? 5 : 0,
					mt: isTablet ? 12 : 6,
					mx: 'auto',
				}}
				id='online-estimate-form'
			>
				<DrivenBox
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						height: '100%',
						width: isTablet ? '50%' : '100%',
						maxWidth: isTablet ? '532px' : '100%'
					}}
				>
					<StaticImage
						src="./images/auto-glass-&-windshield-online-quote.svg"
						alt='Rear windshield replacement'
						layout='constrained'
						width={532}
						quality={100}
					/>
				</DrivenBox>
				<DrivenBox
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '100%',
						width: isTablet ? '50%' : '100%',
						mt: { xs: 5, md: 0 },
					}}
				>
					<DrivenBox sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
						<DrivenBox component='img' src={autoGlassNowLogo} width={isTablet ? '65%' : '100%'} alt='Auto Glass Now logo' />
					</DrivenBox>
					<DrivenBox sx={{ mt: { xs: 2, sm: 4 } }}>
						<DrivenDropdown
							label='Is your auto glass damage on your front windshield?'
							options={glassDamageAnswerOptions}
							onChange={(event) => setGlassDamage(event.target.value as string)}
							placeholder=''
							value={glassDamage}
							sx={{
								...textFieldStyles,
								'& p': {
									color: theme.palette.text.disabled,
								},
							}}
						/>
						{glassDamage === 'yes' && (
							<DrivenDropdown
								label='Does your windshield have 3 or fewer damaged areas that are each smaller than a quarter?'
								options={damagedAreasAnswerOptions}
								onChange={(event) => setDamagedAreas(event.target.value as string)}
								placeholder=''
								value={damagedAreas}
								sx={{
									...textFieldStyles,
									'& p': {
										color: theme.palette.text.disabled,
									},
								}}
							/>
						)}
						{glassDamage === 'yes' && (
							<DrivenDropdown
								label='Will you be using your auto insurance for glass services?'
								options={autoInsuranceAnswerOptions}
								onChange={(event) => setAutoInsurance(event.target.value as string)}
								placeholder=''
								value={autoInsurance}
								sx={{
									...textFieldStyles,
									'& p': {
										color: theme.palette.text.disabled,
									},
								}}
							/>
						)}
					</DrivenBox>
					<DrivenBox sx={{ mt: 1 }}>
						{determineIframe()}
					</DrivenBox>
				</DrivenBox>
			</DrivenBox>
		</DrivenContainer>
	);
};

export default OnlineQuote;
