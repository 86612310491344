import React, { useEffect, useState } from 'react';
import {
	DrivenBox,
	DrivenLink,
	DrivenStack,
	DrivenTypography,
	DrivenButton,
	DrivenContainer,
	DrivenIconButton, DrivenListItem, DrivenList,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { useLocation } from '@reach/router';
import { AnimatePresence } from 'framer-motion';
import { GatsbyLinkBehavior } from './gatsby-link-behavior';
import ContentfulImage from './contentful-image';
import { captureCustomDataLayerEvent } from '../services';
import { AboveNavBar } from '../utils/aboveNavBar';
import { Image } from './AgnCustomHero';

type SubmenuItem = {
	id: string;
	name: string;
	text: string;
	page: {
		path: string;
		slug: string;
	};
};

type NavigationLink = {
	id: string;
	name: string;
	path: string;
	slug: string;
	gtmEventName?: string;
	gtmAdditionalFields?: {
		internal: {
			content: string;
		};
	};
};

type NavigationItem = {
	link: NavigationLink | null;
	linkType: 'Link' | 'Button' | 'customButton';
	displayText: string;
	alternateDisplayText: string | null;
	secondaryDisplayText: string | null;
	submenu?: SubmenuItem[];
};

type UtilityNavigationItem = {
	href: string;
	text: string;
};

type NavigationProps = {
	navigationItems: NavigationItem[];
	utilityNavigationItems: UtilityNavigationItem[];
	logo: Image;
};

const ACCENT_COLOR = '#ffd000';

const utilityNavStyles = (isDesktop: boolean) => ({
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	flexDirection: isDesktop ? 'row' : 'column',
	justifyContent: isDesktop ? 'end' : 'center',
	mt: isDesktop ? 0 : 8,
	p: 0
});

export const Navigation: React.FC<NavigationProps> = ({ navigationItems,utilityNavigationItems, logo }) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [expandedItem, setExpandedItem] = useState<string | null>(null);
	const { pathname } = useLocation();

	const handleGtmEvent = (eventName?: string, additionalFields?: { internal: { content: string } }) => {
		if (eventName) {
			const fields = additionalFields
				? JSON.parse(additionalFields.internal.content)
				: {};
			captureCustomDataLayerEvent(eventName, { ...fields });
		}
	};

	const utilityNavigation = (
		<DrivenList sx={utilityNavStyles(isDesktop)}>
			{utilityNavigationItems.map((navItem) => {
				return (
					<DrivenListItem sx={{ width: 'fit-content' }}>
						<DrivenLink LinkComponent={GatsbyLinkBehavior} href={navItem.href} sx={{
							fontWeight: isDesktop ? 500 : 700,
							color: isDesktop ? theme.palette.common.black : theme.palette.common.white,
						}}>
							{navItem.text}
						</DrivenLink>
					</DrivenListItem>
				)
			})}
		</DrivenList>
	);

	const renderLogo = (
		<DrivenLink href='/' sx={{ display: 'flex', alignItems: 'center', pr: 1 }} LinkComponent={GatsbyLinkBehavior}>
			<ContentfulImage
				image={logo}
				objectFit='contain'
			/>
		</DrivenLink>
	)

	const renderItem = (item: NavigationItem) => {
		switch (item.linkType) {
			case 'Button':
				return (
					<DrivenButton
						key={item.link?.id}
						LinkComponent={GatsbyLinkBehavior}
						href={item.link?.path}
						sx={{ color: theme.palette.common.white, fontWeight: 700 }}
						color='secondary'
						onClick={() => handleGtmEvent(item.link?.gtmEventName, item.link?.gtmAdditionalFields)}
					>
						{item.displayText}
					</DrivenButton>
				);
			case 'customButton':
				return (
					<DrivenButton
						key={item.link?.id}
						sx={{ color: theme.palette.common.white, fontWeight: 700 }}
						href={item.link?.path}
						onClick={() => handleGtmEvent(item.link?.gtmEventName, item.link?.gtmAdditionalFields)}
					>
						{item.alternateDisplayText || item.displayText}
						<DrivenTypography variant='caption' sx={{ fontWeight: 700 }}>
							{item.secondaryDisplayText}
						</DrivenTypography>
					</DrivenButton>
				);
			case 'Link':
				if (item.link) {
					return (
						<DrivenStack flexDirection='row' gap={1.5} alignItems='center'>
							<DrivenLink
								key={item.link.id}
								LinkComponent={GatsbyLinkBehavior}
								href={item.link.path}
								sx={{
									color: theme.palette.common.white,
									fontWeight: 700,
								}}
								onClick={() => handleGtmEvent(item.link?.gtmEventName, item.link?.gtmAdditionalFields)}
							>
								{item.displayText}
							</DrivenLink>
							{item.submenu && (
								<DrivenBox
									component='img'
									src='/assets/images/icons/chevron-down.svg'
									alt='Chevron down icon'
									width={14}
									height={16}
									className='chevron-icon'
									sx={{ transition: 'transform .2s ease'}}
								/>
							)}
						</DrivenStack>
					);
				}

				return (
					<DrivenTypography key={item.displayText} sx={{ color: theme.palette.common.white, fontWeight: 700 }}>
						{item.displayText}
					</DrivenTypography>
				);
			default:
				return null;
		}
	};

	const isActiveLink = (path: string): boolean => `${path}/` === pathname;

	const handleAccordionToggle = (itemId: string) => {
		setExpandedItem(expandedItem === itemId ? null : itemId);
	};

	useEffect(() => {
		setMobileMenuOpen(false);
		setExpandedItem(null);
	}, [pathname]);

	return (
		<>
			{isMobile && pathname !== '/' && pathname !== '/online-estimate/' && (
				<AnimatePresence>
					<AboveNavBar
						bodyText='Get a Free Quote'
					/>
				</AnimatePresence>
			)}
			{isDesktop && (
				<DrivenBox>
					<DrivenContainer maxWidth='lg' sx={{
						height: 44
					}}>
						{utilityNavigation}
					</DrivenContainer>
				</DrivenBox>
			)}
			<DrivenBox as='nav' sx={{
				boxSizing: 'border-box',
				backgroundColor: theme.palette.common.black,
				px: 2,
				height: isDesktop ? '92px' : '76px',
			}}>
				<DrivenContainer maxWidth='lg' sx={{
					height: '100%',
				}}>
					<DrivenStack direction='row' spacing={2} alignItems='center' sx={{ justifyContent: 'space-between' }} height='100%'>
						{renderLogo}
						{!isDesktop && (
							<DrivenIconButton
								onClick={() => setMobileMenuOpen(true)}
							>
								<DrivenBox
									component='img'
									src='/assets/images/icons/burger-menu.svg'
									alt='Open burger menu icon'
									width={24}
									height={24}
								/>
							</DrivenIconButton>
						)}
						{isDesktop ? (
							<DrivenStack direction='row' spacing={5} alignItems='center'>
								{navigationItems.map((item) => (
									<DrivenBox
										key={item.displayText}
										sx={{
											py: 2,
											backgroundColor: theme.palette.common.black,
											color: theme.palette.common.white,
											position: 'relative',
											fontWeight: 700,
											'&:hover .submenu': {
												display: 'block'
											},
											'&:hover': {
												cursor: 'pointer'
											},
											'&:hover .chevron-icon': {
												transform: 'rotate(180deg)',
											},
										}}
									>
										{renderItem(item)}
										{item.submenu && (
											<DrivenStack
												className='submenu'
												direction='column'
												sx={{
													display: 'none',
													position: 'absolute',
													top: '100%',
													left: -16,
													backgroundColor: theme.palette.common.white,
													color: theme.palette.common.black,
													borderRadius: 1,
													zIndex: 100,
													width: 'max-content',
													'&:hover': {
														display: 'block'
													}
												}}
											>
												{item.submenu.map((submenuItem) => (
													<DrivenLink
														display='block'
														width='100%'
														key={submenuItem.id}
														LinkComponent={GatsbyLinkBehavior}
														href={submenuItem.page.path}
														sx={{
															color: theme.palette.common.black,
															fontWeight: 700,
															backgroundColor: isActiveLink(submenuItem.page.path) ? ACCENT_COLOR : 'transparent',
															px: 2,
															py: 0.75,
															'&:hover': {
																borderRadius: 1,
																backgroundColor: ACCENT_COLOR,
																textDecoration: 'none'
															},
														}}
													>
														{submenuItem.text}
													</DrivenLink>
												))}
											</DrivenStack>
										)}
									</DrivenBox>
								))}
							</DrivenStack>
						) : (
							<DrivenBox
								sx={{
									boxSizing: 'border-box',
									position: 'fixed',
									backgroundColor: theme.palette.common.black,
									left: mobileMenuOpen ? 0 : 1200,
									top: 0,
									width: '100%',
									height: '100%',
									transition: 'left .4s',
									zIndex: 10,
									marginLeft: '0!important',
									p: 3
								}}
							>
								<DrivenBox sx={{
									display: 'flex',
									justifyContent: 'space-between',
								}}>
									{renderLogo}
									<DrivenIconButton
										onClick={() => setMobileMenuOpen(false)}
									>
										<DrivenBox
											component='img'
											src='/assets/images/icons/close-menu.svg'
											alt='Close burger menu icon'
											width={24}
											height={24}
										/>
									</DrivenIconButton>
								</DrivenBox>
								<DrivenStack sx={{ mt: 7, gap: 2 }}>
									{navigationItems.map((item,index) => (
										<DrivenStack key={item.displayText} gap={2} mt={index + 1 === navigationItems.length ? 2 : 0}>
											{item.submenu ? (
												<>
													<DrivenStack
														onClick={() => handleAccordionToggle(item.displayText)}
														sx={{
															cursor: 'pointer',
															color: theme.palette.common.white,
															fontWeight: 700,
															borderColor: theme.palette.common.white,
														}}
														flexDirection='row'
														gap={1}
														alignItems='center'
														justifyContent='space-between'
													>
														{item.link ? (
															<DrivenLink
																href={item.link.path}
																LinkComponent={GatsbyLinkBehavior}
																onClick={(e) => {e.stopPropagation()}}
																fontWeight={700}
															>
																{item.displayText}
															</DrivenLink>
														) : (
															<>{item.displayText}</>
														)}
														<DrivenBox
															component='img'
															src='/assets/images/icons/chevron-down.svg'
															alt='Chevron down icon'
															width={21}
															height={21}
															sx={{
																transform: expandedItem ? 'rotate(180deg)' : 'rotate(0deg)',
																transition: 'transform .2s',
															}}
														/>
													</DrivenStack>
												</>
											) : (renderItem(item))}
											{item.submenu && (
												<DrivenBox
													sx={{
														mt: -2,
														ml: 2,
														backgroundColor: theme.palette.common.black,
														transform: expandedItem ? 'scaleY(1)' : 'scaleY(0)',
														transformOrigin: 'top',
														transition: 'transform 0.4s ease',
														overflow: 'hidden',
														height: expandedItem ? 'auto' : 0,
													}}
												>
													{item.submenu.map((submenuItem) => (
														<DrivenLink
															key={submenuItem.id}
															LinkComponent={GatsbyLinkBehavior}
															href={submenuItem.page.path}
															sx={{
																color: isActiveLink(submenuItem.page.path) ? ACCENT_COLOR : theme.palette.common.white,
																borderLeft: isActiveLink(submenuItem.page.path) ? `3px solid ${ACCENT_COLOR}` : 'none',
																fontWeight: 700,
																p: '8px 16px'
															}}
														>
															{submenuItem.text}
														</DrivenLink>
													))}
												</DrivenBox>
											)}
											{index + 1 !== navigationItems.length && (
												<DrivenBox sx={{ borderBottom: `0.5px solid ${theme.palette.common.white}` }}/>
											)}
										</DrivenStack>
									))}
									{utilityNavigation}
								</DrivenStack>
							</DrivenBox>
						)}
					</DrivenStack>
				</DrivenContainer>
			</DrivenBox>
		</>
	);
};
